$(document).ready(function(){

  // MAIN NAV RESPONSIVE CONTROL

  function navSlideOut() {
    $(".nav-container").addClass("nav-show");
    $(".nav-container").removeClass("hidden");
  };

  function navSlideIn() {
    $(".nav-container").addClass("hidden");
    setTimeout(function(){
      $(".nav-container").removeClass("nav-show");
    }, 600);
  };

  $(".nav-toggle").click(function() {
    if ($(".nav-container").hasClass("nav-show")) {
      navSlideIn();
    } else {
      navSlideOut();
    }
  });
  //  FIXED NAV MENU ON SCROLL

  $(window).scroll(function(){
      $('.nav-container').removeClass('nav-show');
      if ($(this).scrollTop() > 160) {
          $('.hide-onload').removeClass('display-none');
          $('.hide-onload').addClass('fixed');

          setTimeout(function(){
          $('.hide-onload').addClass('slow-reveal');
        }, 50);
      } else if ($(this).scrollTop() <= 160) {
        $('.hide-onload').removeClass('slow-reveal');
        $('.hide-onload').removeClass('fixed');
        $('.hide-onload').addClass('display-none');
      }
  });

    // "REFLOW" ELEMENTS (<= 767px DEVICE WIDTH)

  function resize(el1 , el2){
    var $window = $(window);
    if($window.width() <= 767){
         $(el2).insertBefore(el1);
    }else if ($window.width() >= 768){
         $(el2).insertAfter(el1);
    }
  };

  $(window).on('resize' , function(){
     resize('#tablet-text' , '#tablet-png');
  });
  if ($(window).width() <= 767) {
     resize('#tablet-text' , '#tablet-png');
  };

});
